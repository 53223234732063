import React from 'react';
import { TeamPlannerIcon, IntakeGovernanceIcon } from 'components/icons';
import { Edit as EditIcon } from '@material-ui/icons';
import {
  isDevSite,
  isPoc1ENV,
  isPoc2ENV,
  isProdEnv,
  isProdTestEnv,
  isStgEnv
} from 'services/apiEnv.service';
import ProjectsIcon from 'components/icons/Projects.icon';
import MyRequestsIcon from 'components/icons/MyRequests.icon';
import SATAdminIcon from 'components/icons/SATAdmin.icon';
import HelpCenterIcon from 'components/icons/HelpCenter.icon';
import ProgramsIcon from 'components/icons/Programs.icon';

export const drawerWidth = 240;

export const DEFAULT_NAV_LINKS = [
  // { displayName: 'Dashboard', icon: <HomeIcon />, url: '/', name: 'dashboard' },
  // { name: 'Triage', icon: <TriageIcon />, url: `/triage` },
  // { name: 'Projects', icon: <ProjectsIcon />, url: '/projects/explore' },
  {
    displayName: 'Intake & Governance',
    icon: <IntakeGovernanceIcon style={{ width: 20, height: 20 }} />,
    url: '/intake/explore/triage',
    name: 'intake-governance'
  },
  {
    displayName: 'My Dashboard',
    icon: <MyRequestsIcon style={{ width: 20, height: 20 }} />,
    url: '/dashboard/explore/assigned',
    name: 'my-dashboard'
  },
  {
    displayName: 'Requests',
    icon: <ProjectsIcon style={{ width: 20, height: 20 }} />,
    url: '/requests/explore/team',
    name: 'requests'
  },
  {
    displayName: 'Programs',
    icon: <ProgramsIcon style={{ width: 20, height: 20 }} />,
    url: '/programs/explore/department',
    name: 'programs'
  },
  {
    displayName: 'Team Planner',
    icon: <TeamPlannerIcon style={{ width: 20, height: 20 }} />,
    url: '/team-planner',
    name: 'team-planner'
  },
  {
    displayName: 'Help Center',
    icon: <HelpCenterIcon style={{ width: 20, height: 20 }} />,
    url: '/help-center',
    name: 'help-center'
  }
];

export const EDIT_ENV_LINK = {
  displayName: 'Edit Environment',
  icon: <EditIcon style={{ width: 20, height: 20 }} />,
  url: '/edit-env',
  name: 'edit-environment'
};

export const SAT_ADMIN_LINK = {
  displayName: 'Admin',
  icon: <SATAdminIcon style={{ width: 20, height: 20 }} />,
  url: '/admin/sat-admin',
  name: 'admin'
};

export const NAV_LINKS =
  isProdEnv || isProdTestEnv || isStgEnv || isDevSite || isPoc2ENV || isPoc1ENV
    ? [...DEFAULT_NAV_LINKS, SAT_ADMIN_LINK]
    : [...DEFAULT_NAV_LINKS, SAT_ADMIN_LINK, EDIT_ENV_LINK];

export const AVATAR_URL = 'https://webassets.appliedmedical.com/content/IMG/corpteam';
