import { get } from 'services/request.service';
import { getEnv } from 'services/apiEnv.service';
import { user } from 'services/auth.service';
import { TAPIArgs, TModifiedTokenArgs } from 'api/api.types';
import { Employee, EmployeeApproval } from 'components/CommonTypes/Common.types';
import { TeamMember, UserPermissions } from 'components/MeTypes/Me.types';

export const getUserOrg = async ({ id }: TAPIArgs = {}) => {
  return (await get({ url: `${getEnv('BASE_URL')}/orgchart/${id}/desc` })).data;
};

export const getTeamMember = async ({ id }: TAPIArgs = {}) => {
  return (await get({ url: `${getEnv('IT_BASE_URL')}/user/getTeamMemberTeamData/${id}` })).data;
};

export const getListOfUsers = async users => {
  const listOfPromises = await users.map(async user => {
    return await get({ url: `${getEnv('IT_BASE_URL')}/user/getTeamMemberTeamData/${user.value}` });
  });
  const listOfUsers = Promise.all(listOfPromises).then(users => {
    return users.map(user => {
      return user.data;
    });
  });
  return listOfUsers;
};

export const getMe = async (): Promise<TeamMember> => {
  return (await get({ url: `${getEnv('IT_BASE_URL')}/user/getTeamMemberTeamData/${user.TeamId}` }))
    .data;
};

export const searchUsers = (input: string) => {
  // please use searchTeamMembers from the BE in request api unless if that data is missing something
  return get({
    url: `https://aks-cluster-prod.westus3.cloudapp.azure.com/AppliedUser/Search?keyword=${input}&sources=TeamMembers&includeInactive=false`
  });
};

export const searchCostCenters = (input: string) => {
  return get({
    url: `${getEnv('IT_BASE_URL')}/User/SearchAllCostCenters/`,
    params: { searchTerm: input }
  });
};

export const generateApprovalsList = async (
  teamMemberNumber: string | number | null,
  approvalLevel: '' | 'regular' | 'director' | 'escalated'
): Promise<EmployeeApproval[]> => {
  return (
    await get({
      url: `${getEnv(
        'IT_BASE_URL'
      )}/User/GenerateApprovalsList/${teamMemberNumber}?approvalLevel=${approvalLevel}`
    })
  ).data;
};

export const getFilesAuth = async () => {
  return (
    await get({
      url: `${getEnv('IT_BASE_URL')}/user/get-files-auth`
    })
  ).data;
};

export const searchTeamMembers = async (input: string): Promise<Employee[]> => {
  return (
    await get({
      url: `${getEnv('IT_BASE_URL')}/user/team-members`,
      params: { searchTerm: input }
    })
  ).data;
};

export const searchTeamMembersApproval = async (input: string): Promise<EmployeeApproval[]> => {
  return (
    await get({
      url: `${getEnv('IT_BASE_URL')}/user/team-members-approval`,
      params: { searchTerm: input }
    })
  ).data;
};

export const getModifiedUserToken = async (data: TModifiedTokenArgs) => {
  return (
    await get({
      url: `${getEnv('IT_BASE_URL')}/user/getModifiedUserToken`,
      method: 'GET',
      params: data
    })
  ).data;
};

export default { getUserOrg, searchUsers };
