import instance, { get, sharePointInstance } from './request.service';
import { getFeature } from './feature.service';
import {
  TODAY_LS_KEY,
  getEnv,
  isDevEnv,
  isPoc1ENV,
  isPoc2ENV,
  isProdEnv,
  isProdTestEnv,
  isStgEnv
} from './apiEnv.service';
import {
  createDefaultFlags,
  getUserType,
  setUserDetails,
  setUserflags
} from 'utils/userFunctions.utils';
import { DEPARTMENTS } from 'components/WizardTypes/Wizard.types';
export interface IUser {
  AppRoles?: string;
  City?: string;
  CompanyName?: string;
  CostCenter?: string;
  CountryCode?: string;
  FullName?: string;
  TeamId?: number;
  UserType?: any;
  department?: string;
  departmentId?: DEPARTMENTS;
  email?: string;
  featureFlags?: any[];
  firstName?: string;
  groups?: string[];
  isAdmin?: boolean;
  isAustralia?: boolean;
  isEurope?: boolean;
  isIrvine?: boolean;
  isLakeForest?: boolean;
  isPm?: boolean;
  isPmAdmin?: boolean;
  isRSM?: boolean;
  isUS?: boolean;
  isUSCompanyUpdate?: boolean;
  lastName?: string;
  location?: string;
  permissions?: string[];
  subArea?: string;
  username?: string;
}

export const mockUser = {
  status: 200,
  isAuthenticated: true,
  username: 'ja99999',
  firstName: 'John',
  lastName: 'Appleseed',
  isAdmin: true,
  isSales: false,
  isClinicalDev: false,
  isSupervisor: true,
  isUS: true,
  isUSCompanyUpdate: true,
  isRSM: true,
  isLakeForest: false,
  isIrvine: false,
  isAustralia: false,
  isEurope: false,
  CostCenter: '101040',
  TeamId: '99999',
  location: 'Rancho Santa Margarita',
  isPm: false,
  isPmAdmin: false
};

// export const serveConstants = () => {
//   if (isProdEnv) return { origin: 'PROD', pageName: 'SUBMISSION', id: 1 };

//   if (isProdTestEnv) return { origin: 'PLAYGROUND', pageName: 'SUBMISSION_PLAYGROUND', id: 5 };

//   if (isStgEnv) return { origin: 'QA', pageName: 'SUBMISSION_QA', id: 2 };

//   if (isPoc1ENV) return { origin: 'POC1', pageName: 'SUBMISSION_POC1', id: 3 };

//   if (isPoc2ENV) return { origin: 'POC2', pageName: 'SUBMISSION_POC2', id: 4 };

//   if (isDevEnv) return { origin: 'DEV', pageName: 'SUBMISSION_DEV', id: 6 };

//   return { origin: 'DEV', pageName: 'SUBMISSION_DEV', id: 6 };
// };

export const apiEnvLookup: any = {
  'https://requests-poc1.appliedmed.com': 'POC1',
  'https://requests-poc2.appliedmed.com': 'POC2',
  'https://internal-apidev.appliedmed.com/ITProjects': 'DEV',
  'https://apidev.appliedmed.com/ITProjects': 'DEV',
  'https://internal-apiqa.appliedmed.com/ITProjects': 'QA',
  'https://apistg.appliedmed.com/ITProjects': 'QA',
  'https://apistg.appliedmed.com/ITProjects-Playground': 'PG',
  'https://internal-apiqa.appliedmed.com/ITProjects-Playground': 'PG',
  'https://api.appliedmed.com/ITProjects': 'PROD',
  'https://internal-api.appliedmed.com/ITProjects': 'PROD'
};

export const serveEnvCode = () => {
  const savedConstants: any = JSON.parse(localStorage.getItem(TODAY_LS_KEY));
  let defaultEnv = '';
  let currentEnv = '';
  if (savedConstants !== null && savedConstants !== undefined) {
    currentEnv = apiEnvLookup[savedConstants['IT_BASE_URL']];
  } else {
    currentEnv = 'PROD';
  }

  if (isProdEnv) defaultEnv = 'PROD';
  if (isProdTestEnv) defaultEnv = 'PG';
  if (isStgEnv) defaultEnv = 'QA';
  if (isPoc1ENV) defaultEnv = 'POC1';
  if (isPoc2ENV) defaultEnv = 'POC2';
  if (isDevEnv) defaultEnv = 'DEV';

  return { currentEnv, defaultEnv };
};

export const serveMaintenancePageConstants = () => {
  if (isProdEnv)
    return {
      origin: 'PROD',
      pageName: 'ALL',
      id: 1
    };

  if (isProdTestEnv)
    return {
      origin: 'PLAYGROUND',
      pageName: 'ALL_PLAYGROUND',
      id: 5
    };

  if (isStgEnv)
    return {
      origin: 'QA',
      pageName: 'ALL_QA',
      id: 2
    };

  if (isPoc1ENV)
    return {
      origin: 'POC1',
      pageName: 'ALL_POC1',
      id: 3
    };

  if (isPoc2ENV)
    return {
      origin: 'POC2',
      pageName: 'ALL_POC2',
      id: 4
    };

  if (isDevEnv)
    return {
      origin: 'DEV',
      pageName: 'ALL_DEV',
      id: 6
    };

  return {
    origin: 'DEV',
    pageName: 'ALL_DEV',
    id: 6
  };
};

// @ts-ignore
export let isTest = window?.Cypress ? true : false;

export let isAuthenticated = false;
export let user: IUser = isTest ? mockUser : {};
export let RefreshToken: string;
export let JwtToken: string;
export let HasuraJwtToken: string;
export let isSignedInWithAuth0: boolean;

export const setUserWithAuth0 = async (userInfo: any) => {
  // @ts-ignore
  function CheckRoles(element, role) {
    return element.includes(role);
  }

  const { userId, source, groups } = userInfo;

  // Check if groups has the usertype. If not, default to source
  if (!groups.includes('TeamMembers')) {
    groups.push(source);
  }
  const UserType = getUserType(groups);

  // Create an array of all of the flags to make it easier to add/remove flags if needed.
  const defaultFlags = createDefaultFlags();
  let outputUser = { ...defaultFlags };

  const userDetails = setUserDetails(userInfo);
  outputUser = { ...outputUser, ...userDetails, UserType };

  /* Update Boolean flags */
  setUserflags(outputUser, userInfo);

  outputUser.TeamId = userId;

  if (outputUser.isAdmin === true) {
    // Set app roles
    outputUser.AppRoles = [{ RoleId: 4, Role: 'Level 4' }];
  } else {
    const USER_LEVEL_INDEX = groups.findIndex(elem => CheckRoles(elem, 'LEVEL'));
    if (USER_LEVEL_INDEX > -1) {
      // extracting level
      const USER_LEVEL = parseInt(groups[USER_LEVEL_INDEX].slice(-1));
      outputUser.AppRoles = [{ RoleId: USER_LEVEL, Role: `Level ${USER_LEVEL}` }];
    }
  }

  user = outputUser;
};

export const setUserCypress = (userData: any) => {
  user = userData;
};

export const setIsSignedInWithAuth0 = (string: any) => {
  isSignedInWithAuth0 = string;
};

/**
 * TBD: removed for auth0
 */
export const updateGlobalJWT = async (jwt: any) => {
  let oldToken = JwtToken;
  // console.log(`------updateGlobalJWT:\n https://jwt.io/?value=${jwt}`);
  instance.defaults.headers.common['Authorization'] = `Bearer ${jwt}`;
  JwtToken = jwt;

  const maintenancePageConstants = serveMaintenancePageConstants();

  // @ts-ignore
  if (user.TeamId && oldToken !== jwt)
    setFeatures(user.TeamId, user.CostCenter, maintenancePageConstants);
};

export const updateDecisionGlobalJWT = (jwt: any) => {
  decisionInstance.defaults.headers.common.Authorization = `Session ${jwt}`;
};

export const updateSharePointJWT = (jwt: string) => {
  sharePointInstance.defaults.headers.common.Authorization = `Bearer ${jwt}`;
};

export const sharePointLogin = async () => {
  try {
    const { data } = await get({
      method: 'get',
      url: `${getEnv('IT_BASE_URL')}/user/get-files-auth`
    });
    updateSharePointJWT(data.access_token);
    return data.access_token;
  } catch (e) {
    console.log('e', e);
    console.log('failed to obtain sharepoint token');
  }
};

export const setFeatures = async (
  TeamId: any,
  CostCenter: string,
  maintenancePageConstants: any
): void => {
  try {
    await getFeature(
      TeamId,
      CostCenter,
      maintenancePageConstants.origin,
      maintenancePageConstants.id
    );
  } catch (e) {
    console.log(e);
  }
};
