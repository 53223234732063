import { useQuery } from 'react-query';
import { TaskTemplateAPI } from 'api';
import { debounce } from 'lodash';
import { useEffect, useState } from 'react';

export const useGetTaskTemplateLibrary = ({
  params,
  ...config
}: {
  params: { [key: string]: string | number | boolean | null } | {};
}) => {
  const [debouncedParams, setDebouncedParams] = useState(params);
  useEffect(() => {
    const handler = debounce(() => {
      setDebouncedParams(params);
    }, 500);

    handler();

    return () => {
      handler.cancel();
    };
  }, [params]);

  const hasNoParams = Object.keys(params).length === 0;
  const hasValidNameSearch = params['lfilt.NameSearch']?.length >= 3;
  const noNameSearch = !params['lfilt.NameSearch'];

  const isQueryEnabled: boolean = hasNoParams || hasValidNameSearch || noNameSearch;

  return useQuery(
    ['task-template-library', debouncedParams],
    async () => {
      return await TaskTemplateAPI.getTaskTemplateLibrary({ params: debouncedParams });
    },
    {
      staleTime: 1000 * 60 * 5,
      cacheTime: 0,
      keepPreviousData: true,
      enabled: isQueryEnabled,
      ...config
    }
  );
};

export const useGetTaskTemplateDirectory = (directoryId: number) => {
  return useQuery(
    ['task-template-directory', directoryId],
    async () => {
      return await TaskTemplateAPI.getTaskTemplateDirectory({ directoryId });
    },
    {
      staleTime: 1000 * 60 * 5,
      cacheTime: 0,
      enabled: !!directoryId
    }
  );
};

export const useGetTaskTemplateLibraryEnums = () => {
  return useQuery(
    'task-template-library-enums',
    async () => {
      return await TaskTemplateAPI.getTaskTemplateLibraryEnums();
    },
    {
      staleTime: 1000 * 60 * 5,
      cacheTime: 0
    }
  );
};

export const useGetTaskTemplateCollection = ({ taskTemplateId }: { taskTemplateId: number }) => {
  return useQuery(
    ['task-template-collection', taskTemplateId],
    async () => {
      return await TaskTemplateAPI.getTaskTemplateCollection({ taskTemplateId });
    },
    {
      staleTime: 1000 * 60 * 5,
      cacheTime: 0,
      enabled: !!taskTemplateId
    }
  );
};

export const useGetTaskTemplate = ({ taskTemplateId }: { taskTemplateId: number }) => {
  return useQuery(
    ['task-template', taskTemplateId],
    async () => {
      return await TaskTemplateAPI.getTaskTemplate({ taskTemplateId });
    },
    {
      staleTime: 1000 * 60 * 5,
      cacheTime: 0,
      enabled: typeof taskTemplateId === 'number'
    }
  );
};
