import {
  isProdEnv,
  isDevEnv,
  isStgEnv,
  isPoc1ENV,
  isPoc2ENV,
  isProdTestEnv
} from 'services/apiEnv.service';

const serveURL = () => {
  if (isProdEnv) return 'https://my.appliedmedical.com';

  if (isProdTestEnv) return 'https://appliedprodtest.z22.web.core.windows.net';

  if (isStgEnv) return 'https://applied14qa.z1.web.core.windows.net';

  if (isPoc1ENV) return 'https://applied11qa.z1.web.core.windows.net';

  if (isPoc2ENV) return 'https://applied12qa.z1.web.core.windows.net';

  if (isDevEnv) return 'https://applied13qa.z1.web.core.windows.net';

  return 'https://my.appliedmedical.com';
};

export const CONTACT_URL = `${serveURL()}/directory`;

/* Name reflects area where edits can be made */
export enum PERMISSIONTYPES {
  ViewIntakePage = 'IntakeAndGovernancePage.Read',
  RequestTitle = 'RequestTitle.Edit',
  ApprovedRequestApproval = 'RequestRequester.Edit.ForApprovedRequest',
  RequestPriority = 'RequestPriority.Edit',
  RequestType = 'RequestType.Edit',
  RequestUpdateType = 'RequestUpdateType.Edit',
  RequestUpdateCategory = 'RequestUpdateType.Edit',
  RequestDetails = 'RequestDetails.Edit',
  RequestImplementationDate = 'RequestImplementationDate.Edit',
  RequestJustification = 'JustificationNotes.Edit',
  RequestRegions = 'RequestRegions.Edit',
  RequestAddInfo = 'AdditionalInformation.Edit',
  InflightApproval = 'RequestApprover.Edit.ForInFlightRequests',
  RequestTeamsLink = 'Overview.Edit',
  RequestSummary = 'Overview.Edit',
  RequestImpactedRegions = 'Overview.Edit',
  RequestReferenceId = 'Overview.Edit',
  RequestDepedencies = 'Overview.Edit',
  RequestCharter = 'Charter.Edit',
  RequestTeam = 'RequestTeams.Edit',
  RequestStatus = 'Status.Edit',
  RequestClassification = 'StatusClassification.Edit',
  ApproveEscalation = 'StatusEscalation.Approve',
  RequestEscalation = 'Status.EscalationRequest',
  EditProjectPIDPCD = 'StatusPIDPCD.Edit.ForProjectsClassification',
  AddProjectPIDPCD = 'StatusPIDPCD.Add.ForProjectsClassification',
  ScoreCard = 'ScoreCard.Edit',
  ScoreCardComplexity = 'ScoreCardComplexity.Edit',
  ImplementationMethodology = 'ImplementationMethodology.Edit',
  AddTask = 'Task.Edit',
  Files = 'Files.Edit',
  FilesDelete = 'Files.Delete',
  EditNote = 'Notes.Edit',
  AddNote = 'Notes.Add',
  OrganizationEdit = 'Organization.Edit',
  EditTaskTemplates = 'TaskTemplate.Edit',
  JamaUpload = 'Jama.Upload',
  ServiceNowTransfer = 'ServiceNow.Transfer',
  DeleteRequest = 'Request.Delete',
  TeamPlannerEdit = 'TeamPlanner.Edit',
  TeamPlannerAccesss = 'TeamPlanner.Access',
  ProgramEdit = 'Program.Edit',
  ProgramAdd = 'Program.Add',
  CapitalAssetAccess = 'CapitalAsset.Access',
  CapitalAssetEdit = 'CapitalAsset.Edit'
}
